import {toast} from "react-hot-toast";
import React from "react";
import {AxiosError} from "axios";
import {ErrorMessage} from "../../generated";

export async function showErrorSnackbar(error: AxiosError, action?: string) {
    try {
        const message = error.response?.data as ErrorMessage;
        showSnackbar(message.message, action);
    } catch (e) {
        showSnackbar(`${error.response?.status}: ${error.response?.statusText}`);
    }
}

export function showSnackbar(text: string, action?: string, callback?: (id?: string) => {}): string {
    const click = callback
        ? callback
        : !!action
            ? (id: string) => toast.dismiss(id)
            : undefined;
    const id = toast(
        <div className="snackbar-inner">
            <span>{text}</span>
            {action && <button
                className="snackbar-button"
                onClick={() => click ? click(id) : null}
            >
                {action}
            </button>}
        </div>
    );
    return id;
}
