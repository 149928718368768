import {FC} from "react";
import {ButtonBase, Container} from "@mui/material";
import styles from "./DownloadPage.module.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAndroid, faApple} from "@fortawesome/free-brands-svg-icons";


const DownloadPage: FC = () => {
    return <Container maxWidth="md" className={styles.container}>
        <div className={styles.innerContainer}>
            <ButtonBase className={styles.card}>
                <FontAwesomeIcon icon={faAndroid} size="8x" />
                <span>Coming soon</span>
            </ButtonBase>
            <ButtonBase className={styles.card}>
                <FontAwesomeIcon icon={faApple} size="8x" />
                <span>Coming soon</span>
            </ButtonBase>
        </div>
    </Container>
}

export default DownloadPage;
