import React, {FC} from "react";
import {FloatRange} from "../../../../../generated";
import InputField from "../../../input/InputField";
import {InputAdornment} from "@mui/material";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro';

interface Props {
    value: FloatRange;
    onChange: (value: FloatRange) => void;
}

const FloatRangeInput: FC<Props> = ({value, onChange}) => {
    const onInternalChange = (partial: Partial<FloatRange>) => onChange({...value, ...partial});
    return (
        <>
            <div style={{flexGrow: 1}}>
                <InputField
                    fullWidth
                    type="number"
                    value={value.minimum}
                    onChange={e => onInternalChange({minimum: parseFloat(e.target.value)})}
                    inputProps={{
                        style: {
                            marginLeft: 5,
                            marginRight: 5
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={regular('square-minus')} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <div style={{flexGrow: 1}}>
                <InputField
                    fullWidth
                    type="number"
                    value={value.perfect}
                    onChange={e => onInternalChange({perfect: parseFloat(e.target.value)})}
                    inputProps={{
                        style: {
                            marginLeft: 5,
                            marginRight: 5
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={regular('square-check')} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <div style={{flexGrow: 1}}>
                <InputField
                    fullWidth
                    type="number"
                    value={value.maximum}
                    onChange={e => onInternalChange({maximum: parseFloat(e.target.value)})}
                    inputProps={{
                        style: {
                            marginLeft: 5,
                            marginRight: 5
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FontAwesomeIcon icon={regular('square-plus')} />
                            </InputAdornment>
                        )
                    }}
                />
            </div>
        </>
    )
};

export default FloatRangeInput;
