import {createContext, FC, PropsWithChildren, useContext, useState} from "react";
import ApiContext from "../../../../../contextes/api/ApiContext";
import {showErrorSnackbar, showSnackbar} from "../../../../Snackbar";
import {ButtonBase} from "@mui/material";
import styles from "../edit-user/UserDialogProvider.module.css";
import Input from "../../../../input/Input";
import {AxiosError} from "axios";
import EditDialog from "../../../../dialog/EditDialog";

interface State {
    id?: number;
    oldPassword: string;
    newPassword: string;
    secondNewPassword: string;
    open: boolean;
}

interface Context {
    open: (id?: number) => void;
}

export const PasswordDialogContext = createContext<Context>({
    open: (_) => {}
})

const PasswordDialogProvider: FC<PropsWithChildren<{}>> = ({children}) => {
    const api = useContext(ApiContext);
    const [{id, open, oldPassword, newPassword, secondNewPassword}, setState] = useState<State>({
        newPassword: "",
        oldPassword: "",
        open: false,
        secondNewPassword: ""
    });
    const close = () => setState(ps => ({
        ...ps,
        newPassword: "",
        oldPassword: "",
        open: false,
        secondNewPassword: ""
    }));
    const save = () => {
        if ((!id && oldPassword.length === 0) || newPassword.length === 0 || secondNewPassword.length === 0) {
            showSnackbar("All the fields are required", "Dismiss");
            return;
        }
        if (newPassword !== secondNewPassword) {
            showSnackbar("The 2 passwords are not equals", "Dismiss");
            return;
        }
        const promise = !!id
            ? api.adminUsers.updatePassword(id, {newOne: newPassword})
            : api.users.updateOwnPassword({newOne: newPassword, old: oldPassword});
        promise
            .then(() => {
                showSnackbar("Password successfully updated");
                close();
            })
            .catch(async (e: AxiosError) => await showErrorSnackbar(e, "Dismiss"));
    };
    return <PasswordDialogContext.Provider value={{
        open: id => setState({
            id: id,
            newPassword: "",
            oldPassword: "",
            open: true,
            secondNewPassword: ""
        })
    }}>
        {children}
        <EditDialog
            title="Update password"
            dialogProps={{
                onClose: close,
                open: open,
                PaperProps: {className: styles.container},
                fullWidth: true,
                maxWidth: "xs"
            }}
            actions={<>
                <ButtonBase className={styles.button} onClick={close}>
                    Cancel
                </ButtonBase>
                <ButtonBase className={`${styles.button} ${styles.primaryButton}`} onClick={save}>
                    Save
                </ButtonBase>
            </>}
        >
            {
                !id && <Input
                    className={styles.input}
                    label="Current password"
                    type="password"
                    value={oldPassword}
                    onChange={e => setState(ps => ({...ps, oldPassword: e.target.value}))}
                />
            }
            <Input
                className={styles.input}
                label="New password"
                type="password"
                value={newPassword}
                onChange={e => setState(ps => ({...ps, newPassword: e.target.value}))}
            />
            <Input
                className={styles.input}
                label="Confirm new password"
                type="password"
                value={secondNewPassword}
                onChange={e => setState(ps => ({...ps, secondNewPassword: e.target.value}))}
            />
        </EditDialog>
    </PasswordDialogContext.Provider>;
};

export default PasswordDialogProvider;
