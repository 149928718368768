import {Avatar, AvatarProps, CircularProgress} from "@mui/material";
import {FC, useContext, useEffect, useState} from "react";
import ApiContext from "../../contextes/api/ApiContext";
import {AxiosError} from "axios";
import {showErrorSnackbar} from "../Snackbar";
import {Image} from "@mui/icons-material";

interface State {
    isLoading: boolean;
    imageUrl?: string;
}

interface Props extends AvatarProps {
    imageId: number;
}

const AuthorizedAvatar: FC<Props> = ({imageId, ...props}) => {
    const api = useContext(ApiContext);
    const [{isLoading, imageUrl}, setState] = useState<State>({
        isLoading: false
    });
    useEffect(() => {
        if (imageId <= 0) return;
        setState(ps => ({...ps, isLoading: true}));
        api.file
            .getFileContent(imageId, {responseType: 'blob'})
            .then(({data}) => {
                console.log(typeof data)
                const url = URL.createObjectURL(data);
                console.log(url);
                return setState({
                    isLoading: false,
                    imageUrl: url
                })
            })
            .catch(async (e: AxiosError) => {
                await showErrorSnackbar(e, "Retry");
                setState(ps => ({...ps, isLoading: false}));
            });
    }, [imageId, api.file]);
    if (isLoading) return <CircularProgress />;
    return imageUrl
        ? <Avatar {...props} src={imageUrl} />
        : (
            <Avatar {...props}>
                <Image
                    sx={{
                        width: "20px",
                        height: "20px"
                    }}
                />
            </Avatar>
        );
};

export default AuthorizedAvatar;
