/* tslint:disable */
/* eslint-disable */
/**
 * Thirsty plants application service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthenticatedUser
 */
export interface AuthenticatedUser {
    /**
     * 
     * @type {number}
     * @memberof AuthenticatedUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUser
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticatedUser
     */
    avatarImageId: number;
    /**
     * 
     * @type {Tokens}
     * @memberof AuthenticatedUser
     */
    tokens: Tokens;
}
/**
 * 
 * @export
 * @interface AuthenticatedUserAllOf
 */
export interface AuthenticatedUserAllOf {
    /**
     * 
     * @type {Tokens}
     * @memberof AuthenticatedUserAllOf
     */
    tokens: Tokens;
}
/**
 * 
 * @export
 * @interface CreatedSensor
 */
export interface CreatedSensor {
    /**
     * 
     * @type {number}
     * @memberof CreatedSensor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CreatedSensor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatedSensor
     */
    registerToken: string;
}
/**
 * 
 * @export
 * @interface CreatedSensorAllOf
 */
export interface CreatedSensorAllOf {
    /**
     * 
     * @type {string}
     * @memberof CreatedSensorAllOf
     */
    registerToken: string;
}
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    password: string;
}
/**
 * 
 * @export
 * @interface EditFile
 */
export interface EditFile {
    /**
     * 
     * @type {string}
     * @memberof EditFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditFile
     */
    contentType: string;
    /**
     * 
     * @type {number}
     * @memberof EditFile
     */
    size: number;
}
/**
 * 
 * @export
 * @interface EditPlant
 */
export interface EditPlant {
    /**
     * 
     * @type {string}
     * @memberof EditPlant
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof EditPlant
     */
    roomId: number;
    /**
     * 
     * @type {number}
     * @memberof EditPlant
     */
    sensorId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EditPlant
     */
    speciesId: number;
    /**
     * 
     * @type {number}
     * @memberof EditPlant
     */
    imageId: number;
}
/**
 * 
 * @export
 * @interface EditSensor
 */
export interface EditSensor {
    /**
     * 
     * @type {string}
     * @memberof EditSensor
     */
    name: string;
}
/**
 * 
 * @export
 * @interface EditSpecies
 */
export interface EditSpecies {
    /**
     * 
     * @type {number}
     * @memberof EditSpecies
     */
    imageId: number;
    /**
     * 
     * @type {string}
     * @memberof EditSpecies
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditSpecies
     */
    commonName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EditSpecies
     */
    tags: Array<string>;
    /**
     * 
     * @type {SpeciesGrowth}
     * @memberof EditSpecies
     */
    growth: SpeciesGrowth;
}
/**
 * 
 * @export
 * @interface EditUser
 */
export interface EditUser {
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EditUser
     */
    email: string;
}
/**
 * 
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * 
     * @type {string}
     * @memberof ErrorMessage
     */
    message: string;
}
/**
 * 
 * @export
 * @interface FileAllOf
 */
export interface FileAllOf {
    /**
     * 
     * @type {string}
     * @memberof FileAllOf
     */
    contentType: string;
}
/**
 * 
 * @export
 * @interface FloatRange
 */
export interface FloatRange {
    /**
     * 
     * @type {number}
     * @memberof FloatRange
     */
    minimum: number;
    /**
     * 
     * @type {number}
     * @memberof FloatRange
     */
    perfect: number;
    /**
     * 
     * @type {number}
     * @memberof FloatRange
     */
    maximum: number;
}
/**
 * 
 * @export
 * @interface FriendRequest
 */
export interface FriendRequest {
    /**
     * 
     * @type {FriendRequestStatus}
     * @memberof FriendRequest
     */
    status: FriendRequestStatus;
    /**
     * 
     * @type {string}
     * @memberof FriendRequest
     */
    createdDateTime: string;
    /**
     * 
     * @type {User}
     * @memberof FriendRequest
     */
    user: User;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FriendRequestStatus {
    Requested = 'requested',
    Waiting = 'waiting'
}

/**
 * 
 * @export
 * @interface Identifiable
 */
export interface Identifiable {
    /**
     * 
     * @type {number}
     * @memberof Identifiable
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Identifiable
     */
    name: string;
}
/**
 * 
 * @export
 * @interface KeySetPage
 */
export interface KeySetPage {
    /**
     * 
     * @type {number}
     * @memberof KeySetPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof KeySetPage
     */
    nextPageToken?: string | null;
}
/**
 * 
 * @export
 * @interface LightLocation
 */
export interface LightLocation {
    /**
     * 
     * @type {number}
     * @memberof LightLocation
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LightLocation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LightLocation
     */
    city: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof LightLocation
     */
    members: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof LightLocation
     */
    roomCount: number;
}
/**
 * 
 * @export
 * @interface LightLocationAllOf
 */
export interface LightLocationAllOf {
    /**
     * 
     * @type {string}
     * @memberof LightLocationAllOf
     */
    city: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof LightLocationAllOf
     */
    members: Array<User>;
    /**
     * 
     * @type {number}
     * @memberof LightLocationAllOf
     */
    roomCount: number;
}
/**
 * 
 * @export
 * @interface LightLocationPage
 */
export interface LightLocationPage {
    /**
     * 
     * @type {number}
     * @memberof LightLocationPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof LightLocationPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<LightLocation>}
     * @memberof LightLocationPage
     */
    items: Array<LightLocation>;
}
/**
 * 
 * @export
 * @interface LightLocationPageAllOf
 */
export interface LightLocationPageAllOf {
    /**
     * 
     * @type {Array<LightLocation>}
     * @memberof LightLocationPageAllOf
     */
    items: Array<LightLocation>;
}
/**
 * 
 * @export
 * @interface LightPlant
 */
export interface LightPlant {
    /**
     * 
     * @type {number}
     * @memberof LightPlant
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LightPlant
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LightPlant
     */
    imageId: number;
    /**
     * 
     * @type {Room}
     * @memberof LightPlant
     */
    room?: Room | null;
    /**
     * 
     * @type {string}
     * @memberof LightPlant
     */
    lastSeen?: string | null;
}
/**
 * 
 * @export
 * @interface LightPlantAllOf
 */
export interface LightPlantAllOf {
    /**
     * 
     * @type {number}
     * @memberof LightPlantAllOf
     */
    imageId: number;
    /**
     * 
     * @type {Room}
     * @memberof LightPlantAllOf
     */
    room?: Room | null;
    /**
     * 
     * @type {string}
     * @memberof LightPlantAllOf
     */
    lastSeen?: string | null;
}
/**
 * 
 * @export
 * @interface LightPlantPage
 */
export interface LightPlantPage {
    /**
     * 
     * @type {number}
     * @memberof LightPlantPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof LightPlantPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<LightPlant>}
     * @memberof LightPlantPage
     */
    items: Array<LightPlant>;
}
/**
 * 
 * @export
 * @interface LightPlantPageAllOf
 */
export interface LightPlantPageAllOf {
    /**
     * 
     * @type {Array<LightPlant>}
     * @memberof LightPlantPageAllOf
     */
    items: Array<LightPlant>;
}
/**
 * 
 * @export
 * @interface LightSensor
 */
export interface LightSensor {
    /**
     * 
     * @type {number}
     * @memberof LightSensor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LightSensor
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof LightSensor
     */
    batteryPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LightSensor
     */
    lastSeen?: string | null;
    /**
     * 
     * @type {SensorStatus}
     * @memberof LightSensor
     */
    status: SensorStatus;
}
/**
 * 
 * @export
 * @interface LightSensorAllOf
 */
export interface LightSensorAllOf {
    /**
     * 
     * @type {number}
     * @memberof LightSensorAllOf
     */
    batteryPercentage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LightSensorAllOf
     */
    lastSeen?: string | null;
    /**
     * 
     * @type {SensorStatus}
     * @memberof LightSensorAllOf
     */
    status: SensorStatus;
}
/**
 * 
 * @export
 * @interface LightSensorPage
 */
export interface LightSensorPage {
    /**
     * 
     * @type {number}
     * @memberof LightSensorPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof LightSensorPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<LightSensor>}
     * @memberof LightSensorPage
     */
    items: Array<LightSensor>;
}
/**
 * 
 * @export
 * @interface LightSensorPageAllOf
 */
export interface LightSensorPageAllOf {
    /**
     * 
     * @type {Array<LightSensor>}
     * @memberof LightSensorPageAllOf
     */
    items: Array<LightSensor>;
}
/**
 * 
 * @export
 * @interface LightSpecies
 */
export interface LightSpecies {
    /**
     * 
     * @type {number}
     * @memberof LightSpecies
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LightSpecies
     */
    name: string;
    /**
     * 
     * @type {Identifiable}
     * @memberof LightSpecies
     */
    image: Identifiable;
    /**
     * 
     * @type {string}
     * @memberof LightSpecies
     */
    commonName: string;
}
/**
 * 
 * @export
 * @interface LightSpeciesAllOf
 */
export interface LightSpeciesAllOf {
    /**
     * 
     * @type {Identifiable}
     * @memberof LightSpeciesAllOf
     */
    image: Identifiable;
    /**
     * 
     * @type {string}
     * @memberof LightSpeciesAllOf
     */
    commonName: string;
}
/**
 * 
 * @export
 * @interface LightSpeciesPage
 */
export interface LightSpeciesPage {
    /**
     * 
     * @type {number}
     * @memberof LightSpeciesPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof LightSpeciesPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<LightSpecies>}
     * @memberof LightSpeciesPage
     */
    items: Array<LightSpecies>;
}
/**
 * 
 * @export
 * @interface LightSpeciesPageAllOf
 */
export interface LightSpeciesPageAllOf {
    /**
     * 
     * @type {Array<LightSpecies>}
     * @memberof LightSpeciesPageAllOf
     */
    items: Array<LightSpecies>;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    city: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof Location
     */
    members: Array<User>;
    /**
     * 
     * @type {Array<Identifiable>}
     * @memberof Location
     */
    rooms: Array<Identifiable>;
}
/**
 * 
 * @export
 * @interface LocationAllOf
 */
export interface LocationAllOf {
    /**
     * 
     * @type {string}
     * @memberof LocationAllOf
     */
    city: string;
    /**
     * 
     * @type {Array<User>}
     * @memberof LocationAllOf
     */
    members: Array<User>;
    /**
     * 
     * @type {Array<Identifiable>}
     * @memberof LocationAllOf
     */
    rooms: Array<Identifiable>;
}
/**
 * 
 * @export
 * @interface MeasuredValue
 */
export interface MeasuredValue {
    /**
     * 
     * @type {string}
     * @memberof MeasuredValue
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof MeasuredValue
     */
    value: number;
}
/**
 * 
 * @export
 * @interface MeasuredValues
 */
export interface MeasuredValues {
    /**
     * 
     * @type {MeasuredValue}
     * @memberof MeasuredValues
     */
    moisture: MeasuredValue;
    /**
     * 
     * @type {MeasuredValue}
     * @memberof MeasuredValues
     */
    lightExposure: MeasuredValue;
    /**
     * 
     * @type {MeasuredValue}
     * @memberof MeasuredValues
     */
    temperature: MeasuredValue;
    /**
     * 
     * @type {MeasuredValue}
     * @memberof MeasuredValues
     */
    humidity: MeasuredValue;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    contentType: string;
}
/**
 * 
 * @export
 * @interface NewLocation
 */
export interface NewLocation {
    /**
     * 
     * @type {string}
     * @memberof NewLocation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NewLocation
     */
    city: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewLocation
     */
    memberIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewLocation
     */
    rooms: Array<string>;
}
/**
 * 
 * @export
 * @interface NewPassword
 */
export interface NewPassword {
    /**
     * 
     * @type {string}
     * @memberof NewPassword
     */
    newOne: string;
}
/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    password: string;
}
/**
 * 
 * @export
 * @interface NewUserAllOf
 */
export interface NewUserAllOf {
    /**
     * 
     * @type {string}
     * @memberof NewUserAllOf
     */
    password: string;
}
/**
 * 
 * @export
 * @interface Passwords
 */
export interface Passwords {
    /**
     * 
     * @type {string}
     * @memberof Passwords
     */
    newOne: string;
    /**
     * 
     * @type {string}
     * @memberof Passwords
     */
    old: string;
}
/**
 * 
 * @export
 * @interface PasswordsAllOf
 */
export interface PasswordsAllOf {
    /**
     * 
     * @type {string}
     * @memberof PasswordsAllOf
     */
    old: string;
}
/**
 * 
 * @export
 * @interface Plant
 */
export interface Plant {
    /**
     * 
     * @type {number}
     * @memberof Plant
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Plant
     */
    imageId: number;
    /**
     * 
     * @type {Room}
     * @memberof Plant
     */
    room?: Room | null;
    /**
     * 
     * @type {string}
     * @memberof Plant
     */
    lastSeen?: string | null;
    /**
     * 
     * @type {LightSpecies}
     * @memberof Plant
     */
    species: LightSpecies;
    /**
     * 
     * @type {MeasuredValues}
     * @memberof Plant
     */
    measures?: MeasuredValues | null;
    /**
     * 
     * @type {LightSensor}
     * @memberof Plant
     */
    sensor?: LightSensor | null;
}
/**
 * 
 * @export
 * @interface PlantAllOf
 */
export interface PlantAllOf {
    /**
     * 
     * @type {LightSpecies}
     * @memberof PlantAllOf
     */
    species: LightSpecies;
    /**
     * 
     * @type {MeasuredValues}
     * @memberof PlantAllOf
     */
    measures?: MeasuredValues | null;
    /**
     * 
     * @type {LightSensor}
     * @memberof PlantAllOf
     */
    sensor?: LightSensor | null;
}
/**
 * 
 * @export
 * @interface PlantLocation
 */
export interface PlantLocation {
    /**
     * 
     * @type {Identifiable}
     * @memberof PlantLocation
     */
    location: Identifiable;
    /**
     * 
     * @type {Identifiable}
     * @memberof PlantLocation
     */
    room: Identifiable;
}
/**
 * 
 * @export
 * @interface RawMeasures
 */
export interface RawMeasures {
    /**
     * 
     * @type {number}
     * @memberof RawMeasures
     */
    moisture: number;
    /**
     * 
     * @type {number}
     * @memberof RawMeasures
     */
    humidity: number;
    /**
     * 
     * @type {number}
     * @memberof RawMeasures
     */
    lightExposure: number;
    /**
     * 
     * @type {number}
     * @memberof RawMeasures
     */
    battery: number;
    /**
     * 
     * @type {number}
     * @memberof RawMeasures
     */
    temperature: number;
    /**
     * 
     * @type {string}
     * @memberof RawMeasures
     */
    creationDateTime: string;
}
/**
 * 
 * @export
 * @interface RawMeasuresPage
 */
export interface RawMeasuresPage {
    /**
     * 
     * @type {number}
     * @memberof RawMeasuresPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof RawMeasuresPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<RawMeasures>}
     * @memberof RawMeasuresPage
     */
    items: Array<RawMeasures>;
}
/**
 * 
 * @export
 * @interface RawMeasuresPageAllOf
 */
export interface RawMeasuresPageAllOf {
    /**
     * 
     * @type {Array<RawMeasures>}
     * @memberof RawMeasuresPageAllOf
     */
    items: Array<RawMeasures>;
}
/**
 * 
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * 
     * @type {number}
     * @memberof Room
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Room
     */
    name: string;
    /**
     * 
     * @type {LightLocation}
     * @memberof Room
     */
    location: LightLocation;
}
/**
 * 
 * @export
 * @interface RoomAllOf
 */
export interface RoomAllOf {
    /**
     * 
     * @type {LightLocation}
     * @memberof RoomAllOf
     */
    location: LightLocation;
}
/**
 * 
 * @export
 * @interface RoomPage
 */
export interface RoomPage {
    /**
     * 
     * @type {number}
     * @memberof RoomPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof RoomPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<Room>}
     * @memberof RoomPage
     */
    items: Array<Room>;
}
/**
 * 
 * @export
 * @interface RoomPageAllOf
 */
export interface RoomPageAllOf {
    /**
     * 
     * @type {Array<Room>}
     * @memberof RoomPageAllOf
     */
    items: Array<Room>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SensorStatus {
    Ok = 'ok',
    ConnectivityError = 'connectivityError',
    UnknownError = 'unknownError',
    NeverSeen = 'neverSeen'
}

/**
 * 
 * @export
 * @interface Species
 */
export interface Species {
    /**
     * 
     * @type {number}
     * @memberof Species
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Species
     */
    name: string;
    /**
     * 
     * @type {Identifiable}
     * @memberof Species
     */
    image: Identifiable;
    /**
     * 
     * @type {string}
     * @memberof Species
     */
    commonName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Species
     */
    tags: Array<string>;
    /**
     * 
     * @type {SpeciesGrowth}
     * @memberof Species
     */
    growth: SpeciesGrowth;
}
/**
 * 
 * @export
 * @interface SpeciesAllOf
 */
export interface SpeciesAllOf {
    /**
     * 
     * @type {Array<string>}
     * @memberof SpeciesAllOf
     */
    tags: Array<string>;
    /**
     * 
     * @type {SpeciesGrowth}
     * @memberof SpeciesAllOf
     */
    growth: SpeciesGrowth;
}
/**
 * 
 * @export
 * @interface SpeciesGrowth
 */
export interface SpeciesGrowth {
    /**
     * 
     * @type {FloatRange}
     * @memberof SpeciesGrowth
     */
    soilMoisture: FloatRange;
    /**
     * 
     * @type {FloatRange}
     * @memberof SpeciesGrowth
     */
    lightExposure: FloatRange;
    /**
     * 
     * @type {FloatRange}
     * @memberof SpeciesGrowth
     */
    temperature: FloatRange;
    /**
     * 
     * @type {FloatRange}
     * @memberof SpeciesGrowth
     */
    humidity: FloatRange;
}
/**
 * 
 * @export
 * @interface Tokens
 */
export interface Tokens {
    /**
     * 
     * @type {string}
     * @memberof Tokens
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof Tokens
     */
    refreshToken: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    avatarImageId: number;
}
/**
 * 
 * @export
 * @interface UserPage
 */
export interface UserPage {
    /**
     * 
     * @type {number}
     * @memberof UserPage
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof UserPage
     */
    nextPageToken?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPage
     */
    items: Array<User>;
}
/**
 * 
 * @export
 * @interface UserPageAllOf
 */
export interface UserPageAllOf {
    /**
     * 
     * @type {Array<User>}
     * @memberof UserPageAllOf
     */
    items: Array<User>;
}

/**
 * AdminUserApi - axios parameter creator
 * @export
 */
export const AdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (newUser: NewUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newUser' is not null or undefined
            assertParamExists('createUser', 'newUser', newUser)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword: async (id: number, newPassword: NewPassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePassword', 'id', id)
            // verify required parameter 'newPassword' is not null or undefined
            assertParamExists('updatePassword', 'newPassword', newPassword)
            const localVarPath = `/user/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: number, editUser: EditUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'editUser' is not null or undefined
            assertParamExists('updateUser', 'editUser', editUser)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserApi - functional programming interface
 * @export
 */
export const AdminUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(newUser: NewUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(newUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePassword(id: number, newPassword: NewPassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePassword(id, newPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: number, editUser: EditUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, editUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserApi - factory interface
 * @export
 */
export const AdminUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserApiFp(configuration)
    return {
        /**
         * 
         * @param {NewUser} newUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(newUser: NewUser, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {NewPassword} newPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePassword(id: number, newPassword: NewPassword, options?: any): AxiosPromise<User> {
            return localVarFp.updatePassword(id, newPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: number, editUser: EditUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateUser(id, editUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
export class AdminUserApi extends BaseAPI {
    /**
     * 
     * @param {NewUser} newUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public createUser(newUser: NewUser, options?: any) {
        return AdminUserApiFp(this.configuration).createUser(newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {NewPassword} newPassword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public updatePassword(id: number, newPassword: NewPassword, options?: any) {
        return AdminUserApiFp(this.configuration).updatePassword(id, newPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditUser} editUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    public updateUser(id: number, editUser: EditUser, options?: any) {
        return AdminUserApiFp(this.configuration).updateUser(id, editUser, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (credentials: Credentials, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'credentials' is not null or undefined
            assertParamExists('login', 'credentials', credentials)
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (tokens: Tokens, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokens' is not null or undefined
            assertParamExists('logout', 'tokens', tokens)
            const localVarPath = `/authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication RefreshToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(credentials: Credentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(tokens: Tokens, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(tokens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {Credentials} credentials 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(credentials: Credentials, options?: any): AxiosPromise<AuthenticatedUser> {
            return localVarFp.login(credentials, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Tokens} tokens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(tokens: Tokens, options?: any): AxiosPromise<Tokens> {
            return localVarFp.logout(tokens, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options?: any): AxiosPromise<Tokens> {
            return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {Credentials} credentials 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(credentials: Credentials, options?: any) {
        return AuthenticationApiFp(this.configuration).login(credentials, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Tokens} tokens 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(tokens: Tokens, options?: any) {
        return AuthenticationApiFp(this.configuration).logout(tokens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public refreshToken(options?: any) {
        return AuthenticationApiFp(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile: async (editFile: EditFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editFile' is not null or undefined
            assertParamExists('createFile', 'editFile', editFile)
            const localVarPath = `/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContent: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFileContent', 'id', id)
            const localVarPath = `/file/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileContent: async (id: number, body: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadFileContent', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('uploadFileContent', 'body', body)
            const localVarPath = `/file/{id}/content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFile(editFile: EditFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFile(editFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileContent(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileContent(id: number, body: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileContent(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFile(editFile: EditFile, options?: any): AxiosPromise<any> {
            return localVarFp.createFile(editFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileContent(id: number, options?: any): AxiosPromise<any> {
            return localVarFp.getFileContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileContent(id: number, body: any, options?: any): AxiosPromise<void> {
            return localVarFp.uploadFileContent(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {EditFile} editFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public createFile(editFile: EditFile, options?: any) {
        return FileApiFp(this.configuration).createFile(editFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileContent(id: number, options?: any) {
        return FileApiFp(this.configuration).getFileContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadFileContent(id: number, body: any, options?: any) {
        return FileApiFp(this.configuration).uploadFileContent(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FriendApi - axios parameter creator
 * @export
 */
export const FriendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriendPage: async (size: number, filter?: string, paginationToken?: string, filteredIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getFriendPage', 'size', size)
            const localVarPath = `/friend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }

            if (filteredIds) {
                localVarQueryParameter['filteredIds'] = filteredIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriendRequests: async (filter?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/friend/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFriend: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('requestFriend', 'userId', userId)
            const localVarPath = `/friend/{userId}/request`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFriendRequest: async (userId: number, body: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('validateFriendRequest', 'userId', userId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('validateFriendRequest', 'body', body)
            const localVarPath = `/friend/{userId}/validate`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FriendApi - functional programming interface
 * @export
 */
export const FriendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FriendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFriendPage(size: number, filter?: string, paginationToken?: string, filteredIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFriendPage(size, filter, paginationToken, filteredIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFriendRequests(filter?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FriendRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFriendRequests(filter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestFriend(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestFriend(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateFriendRequest(userId: number, body: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateFriendRequest(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FriendApi - factory interface
 * @export
 */
export const FriendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FriendApiFp(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriendPage(size: number, filter?: string, paginationToken?: string, filteredIds?: Array<number>, options?: any): AxiosPromise<UserPage> {
            return localVarFp.getFriendPage(size, filter, paginationToken, filteredIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [filter] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFriendRequests(filter?: string, options?: any): AxiosPromise<Array<FriendRequest>> {
            return localVarFp.getFriendRequests(filter, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestFriend(userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.requestFriend(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {boolean} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateFriendRequest(userId: number, body: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.validateFriendRequest(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FriendApi - object-oriented interface
 * @export
 * @class FriendApi
 * @extends {BaseAPI}
 */
export class FriendApi extends BaseAPI {
    /**
     * 
     * @param {number} size 
     * @param {string} [filter] 
     * @param {string} [paginationToken] 
     * @param {Array<number>} [filteredIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public getFriendPage(size: number, filter?: string, paginationToken?: string, filteredIds?: Array<number>, options?: any) {
        return FriendApiFp(this.configuration).getFriendPage(size, filter, paginationToken, filteredIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public getFriendRequests(filter?: string, options?: any) {
        return FriendApiFp(this.configuration).getFriendRequests(filter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public requestFriend(userId: number, options?: any) {
        return FriendApiFp(this.configuration).requestFriend(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {boolean} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public validateFriendRequest(userId: number, body: boolean, options?: any) {
        return FriendApiFp(this.configuration).validateFriendRequest(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NewLocation} newLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (newLocation: NewLocation, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'newLocation' is not null or undefined
            assertParamExists('createLocation', 'newLocation', newLocation)
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newLocation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocation', 'id', id)
            const localVarPath = `/location/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations: async (size: number, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getLocations', 'size', size)
            const localVarPath = `/location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NewLocation} newLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(newLocation: NewLocation, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(newLocation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocation(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Location>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocations(size: number, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightLocationPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocations(size, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * 
         * @param {NewLocation} newLocation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(newLocation: NewLocation, options?: any): AxiosPromise<Location> {
            return localVarFp.createLocation(newLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocation(id: number, options?: any): AxiosPromise<Location> {
            return localVarFp.getLocation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocations(size: number, paginationToken?: string, options?: any): AxiosPromise<LightLocationPage> {
            return localVarFp.getLocations(size, paginationToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * 
     * @param {NewLocation} newLocation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public createLocation(newLocation: NewLocation, options?: any) {
        return LocationApiFp(this.configuration).createLocation(newLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocation(id: number, options?: any) {
        return LocationApiFp(this.configuration).getLocation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocations(size: number, paginationToken?: string, options?: any) {
        return LocationApiFp(this.configuration).getLocations(size, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MeasureApi - axios parameter creator
 * @export
 */
export const MeasureApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} size 
         * @param {string} minDateTime 
         * @param {string} maxDateTime 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorMeasures: async (id: number, size: number, minDateTime: string, maxDateTime: string, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSensorMeasures', 'id', id)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getSensorMeasures', 'size', size)
            // verify required parameter 'minDateTime' is not null or undefined
            assertParamExists('getSensorMeasures', 'minDateTime', minDateTime)
            // verify required parameter 'maxDateTime' is not null or undefined
            assertParamExists('getSensorMeasures', 'maxDateTime', maxDateTime)
            const localVarPath = `/sensor/{id}/measure`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }

            if (minDateTime !== undefined) {
                localVarQueryParameter['minDateTime'] = (minDateTime as any instanceof Date) ?
                    (minDateTime as any).toISOString() :
                    minDateTime;
            }

            if (maxDateTime !== undefined) {
                localVarQueryParameter['maxDateTime'] = (maxDateTime as any instanceof Date) ?
                    (maxDateTime as any).toISOString() :
                    maxDateTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeasureApi - functional programming interface
 * @export
 */
export const MeasureApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MeasureApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} size 
         * @param {string} minDateTime 
         * @param {string} maxDateTime 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensorMeasures(id: number, size: number, minDateTime: string, maxDateTime: string, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RawMeasuresPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensorMeasures(id, size, minDateTime, maxDateTime, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MeasureApi - factory interface
 * @export
 */
export const MeasureApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MeasureApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {number} size 
         * @param {string} minDateTime 
         * @param {string} maxDateTime 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorMeasures(id: number, size: number, minDateTime: string, maxDateTime: string, paginationToken?: string, options?: any): AxiosPromise<RawMeasuresPage> {
            return localVarFp.getSensorMeasures(id, size, minDateTime, maxDateTime, paginationToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MeasureApi - object-oriented interface
 * @export
 * @class MeasureApi
 * @extends {BaseAPI}
 */
export class MeasureApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {number} size 
     * @param {string} minDateTime 
     * @param {string} maxDateTime 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeasureApi
     */
    public getSensorMeasures(id: number, size: number, minDateTime: string, maxDateTime: string, paginationToken?: string, options?: any) {
        return MeasureApiFp(this.configuration).getSensorMeasures(id, size, minDateTime, maxDateTime, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PlantApi - axios parameter creator
 * @export
 */
export const PlantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlant: async (editPlant: EditPlant, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editPlant' is not null or undefined
            assertParamExists('createPlant', 'editPlant', editPlant)
            const localVarPath = `/plant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPlant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlant: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePlant', 'id', id)
            const localVarPath = `/plant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlant: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPlant', 'id', id)
            const localVarPath = `/plant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantPage: async (size: number, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getPlantPage', 'size', size)
            const localVarPath = `/plant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlant: async (id: number, editPlant: EditPlant, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePlant', 'id', id)
            // verify required parameter 'editPlant' is not null or undefined
            assertParamExists('updatePlant', 'editPlant', editPlant)
            const localVarPath = `/plant/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editPlant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlantApi - functional programming interface
 * @export
 */
export const PlantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPlant(editPlant: EditPlant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPlant(editPlant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePlant(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlant(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlantPage(size: number, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightPlantPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlantPage(size, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePlant(id: number, editPlant: EditPlant, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Plant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlant(id, editPlant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PlantApi - factory interface
 * @export
 */
export const PlantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlantApiFp(configuration)
    return {
        /**
         * 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPlant(editPlant: EditPlant, options?: any): AxiosPromise<Plant> {
            return localVarFp.createPlant(editPlant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePlant(id: number, options?: any): AxiosPromise<Plant> {
            return localVarFp.deletePlant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlant(id: number, options?: any): AxiosPromise<Plant> {
            return localVarFp.getPlant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantPage(size: number, paginationToken?: string, options?: any): AxiosPromise<LightPlantPage> {
            return localVarFp.getPlantPage(size, paginationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditPlant} editPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePlant(id: number, editPlant: EditPlant, options?: any): AxiosPromise<Plant> {
            return localVarFp.updatePlant(id, editPlant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlantApi - object-oriented interface
 * @export
 * @class PlantApi
 * @extends {BaseAPI}
 */
export class PlantApi extends BaseAPI {
    /**
     * 
     * @param {EditPlant} editPlant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantApi
     */
    public createPlant(editPlant: EditPlant, options?: any) {
        return PlantApiFp(this.configuration).createPlant(editPlant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantApi
     */
    public deletePlant(id: number, options?: any) {
        return PlantApiFp(this.configuration).deletePlant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantApi
     */
    public getPlant(id: number, options?: any) {
        return PlantApiFp(this.configuration).getPlant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantApi
     */
    public getPlantPage(size: number, paginationToken?: string, options?: any) {
        return PlantApiFp(this.configuration).getPlantPage(size, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditPlant} editPlant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantApi
     */
    public updatePlant(id: number, editPlant: EditPlant, options?: any) {
        return PlantApiFp(this.configuration).updatePlant(id, editPlant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoomApi - axios parameter creator
 * @export
 */
export const RoomApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: async (size: number, filter?: string, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getRooms', 'size', size)
            const localVarPath = `/room`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomApi - functional programming interface
 * @export
 */
export const RoomApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRooms(size: number, filter?: string, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoomPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRooms(size, filter, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomApi - factory interface
 * @export
 */
export const RoomApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomApiFp(configuration)
    return {
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms(size: number, filter?: string, paginationToken?: string, options?: any): AxiosPromise<RoomPage> {
            return localVarFp.getRooms(size, filter, paginationToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoomApi - object-oriented interface
 * @export
 * @class RoomApi
 * @extends {BaseAPI}
 */
export class RoomApi extends BaseAPI {
    /**
     * 
     * @param {number} size 
     * @param {string} [filter] 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomApi
     */
    public getRooms(size: number, filter?: string, paginationToken?: string, options?: any) {
        return RoomApiFp(this.configuration).getRooms(size, filter, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SensorApi - axios parameter creator
 * @export
 */
export const SensorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSensor: async (editSensor: EditSensor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSensor' is not null or undefined
            assertParamExists('addSensor', 'editSensor', editSensor)
            const localVarPath = `/sensor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSensor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSensor: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSensor', 'id', id)
            const localVarPath = `/sensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSensor', 'id', id)
            const localVarPath = `/sensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorPage: async (size: number, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getSensorPage', 'size', size)
            const localVarPath = `/sensor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensor: async (id: number, editSensor: EditSensor, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSensor', 'id', id)
            // verify required parameter 'editSensor' is not null or undefined
            assertParamExists('updateSensor', 'editSensor', editSensor)
            const localVarPath = `/sensor/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSensor, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SensorApi - functional programming interface
 * @export
 */
export const SensorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SensorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSensor(editSensor: EditSensor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSensor(editSensor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSensor(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Identifiable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSensor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensor(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightSensor>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensor(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSensorPage(size: number, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightSensorPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSensorPage(size, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSensor(id: number, editSensor: EditSensor, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Identifiable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSensor(id, editSensor, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SensorApi - factory interface
 * @export
 */
export const SensorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SensorApiFp(configuration)
    return {
        /**
         * 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSensor(editSensor: EditSensor, options?: any): AxiosPromise<CreatedSensor> {
            return localVarFp.addSensor(editSensor, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSensor(id: number, options?: any): AxiosPromise<Identifiable> {
            return localVarFp.deleteSensor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensor(id: number, options?: any): AxiosPromise<LightSensor> {
            return localVarFp.getSensor(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSensorPage(size: number, paginationToken?: string, options?: any): AxiosPromise<LightSensorPage> {
            return localVarFp.getSensorPage(size, paginationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSensor} editSensor 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSensor(id: number, editSensor: EditSensor, options?: any): AxiosPromise<Identifiable> {
            return localVarFp.updateSensor(id, editSensor, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SensorApi - object-oriented interface
 * @export
 * @class SensorApi
 * @extends {BaseAPI}
 */
export class SensorApi extends BaseAPI {
    /**
     * 
     * @param {EditSensor} editSensor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public addSensor(editSensor: EditSensor, options?: any) {
        return SensorApiFp(this.configuration).addSensor(editSensor, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public deleteSensor(id: number, options?: any) {
        return SensorApiFp(this.configuration).deleteSensor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public getSensor(id: number, options?: any) {
        return SensorApiFp(this.configuration).getSensor(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public getSensorPage(size: number, paginationToken?: string, options?: any) {
        return SensorApiFp(this.configuration).getSensorPage(size, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditSensor} editSensor 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorApi
     */
    public updateSensor(id: number, editSensor: EditSensor, options?: any) {
        return SensorApiFp(this.configuration).updateSensor(id, editSensor, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SpeciesApi - axios parameter creator
 * @export
 */
export const SpeciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpecies: async (editSpecies: EditSpecies, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSpecies' is not null or undefined
            assertParamExists('createSpecies', 'editSpecies', editSpecies)
            const localVarPath = `/species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSpecies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecies: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSpecies', 'id', id)
            const localVarPath = `/species/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecies: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSpecies', 'id', id)
            const localVarPath = `/species/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeciesPage: async (size: number, filter?: string, paginationToken?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getSpeciesPage', 'size', size)
            const localVarPath = `/species`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecies: async (id: number, editSpecies: EditSpecies, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSpecies', 'id', id)
            // verify required parameter 'editSpecies' is not null or undefined
            assertParamExists('updateSpecies', 'editSpecies', editSpecies)
            const localVarPath = `/species/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSpecies, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeciesImage: async (id: number, editFile: EditFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSpeciesImage', 'id', id)
            // verify required parameter 'editFile' is not null or undefined
            assertParamExists('updateSpeciesImage', 'editFile', editFile)
            const localVarPath = `/species/{id}/image`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpeciesApi - functional programming interface
 * @export
 */
export const SpeciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpeciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSpecies(editSpecies: EditSpecies, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSpecies(editSpecies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSpecies(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSpecies(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpecies(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpecies(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSpeciesPage(size: number, filter?: string, paginationToken?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LightSpeciesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSpeciesPage(size, filter, paginationToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpecies(id: number, editSpecies: EditSpecies, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Species>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpecies(id, editSpecies, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSpeciesImage(id: number, editFile: EditFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSpeciesImage(id, editFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpeciesApi - factory interface
 * @export
 */
export const SpeciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpeciesApiFp(configuration)
    return {
        /**
         * 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSpecies(editSpecies: EditSpecies, options?: any): AxiosPromise<Species> {
            return localVarFp.createSpecies(editSpecies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSpecies(id: number, options?: any): AxiosPromise<Species> {
            return localVarFp.deleteSpecies(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecies(id: number, options?: any): AxiosPromise<Species> {
            return localVarFp.getSpecies(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [filter] 
         * @param {string} [paginationToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpeciesPage(size: number, filter?: string, paginationToken?: string, options?: any): AxiosPromise<LightSpeciesPage> {
            return localVarFp.getSpeciesPage(size, filter, paginationToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditSpecies} editSpecies 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpecies(id: number, editSpecies: EditSpecies, options?: any): AxiosPromise<Species> {
            return localVarFp.updateSpecies(id, editSpecies, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSpeciesImage(id: number, editFile: EditFile, options?: any): AxiosPromise<any> {
            return localVarFp.updateSpeciesImage(id, editFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpeciesApi - object-oriented interface
 * @export
 * @class SpeciesApi
 * @extends {BaseAPI}
 */
export class SpeciesApi extends BaseAPI {
    /**
     * 
     * @param {EditSpecies} editSpecies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public createSpecies(editSpecies: EditSpecies, options?: any) {
        return SpeciesApiFp(this.configuration).createSpecies(editSpecies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public deleteSpecies(id: number, options?: any) {
        return SpeciesApiFp(this.configuration).deleteSpecies(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getSpecies(id: number, options?: any) {
        return SpeciesApiFp(this.configuration).getSpecies(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {string} [filter] 
     * @param {string} [paginationToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public getSpeciesPage(size: number, filter?: string, paginationToken?: string, options?: any) {
        return SpeciesApiFp(this.configuration).getSpeciesPage(size, filter, paginationToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditSpecies} editSpecies 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public updateSpecies(id: number, editSpecies: EditSpecies, options?: any) {
        return SpeciesApiFp(this.configuration).updateSpecies(id, editSpecies, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {EditFile} editFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpeciesApi
     */
    public updateSpeciesImage(id: number, editFile: EditFile, options?: any) {
        return SpeciesApiFp(this.configuration).updateSpeciesImage(id, editFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {string} [filter] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPage: async (size: number, paginationToken?: string, filter?: string, filteredIds?: Array<number>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getUserPage', 'size', size)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (paginationToken !== undefined) {
                localVarQueryParameter['paginationToken'] = paginationToken;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (filteredIds) {
                localVarQueryParameter['filteredIds'] = filteredIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNotificationToken: async (body: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('registerNotificationToken', 'body', body)
            const localVarPath = `/user/me/notification/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnAvatar: async (editFile: EditFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editFile' is not null or undefined
            assertParamExists('updateOwnAvatar', 'editFile', editFile)
            const localVarPath = `/user/me/avatar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Passwords} passwords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword: async (passwords: Passwords, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwords' is not null or undefined
            assertParamExists('updateOwnPassword', 'passwords', passwords)
            const localVarPath = `/user/me/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwords, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnUser: async (editUser: EditUser, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editUser' is not null or undefined
            assertParamExists('updateOwnUser', 'editUser', editUser)
            const localVarPath = `/user/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOwnUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOwnUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {string} [filter] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPage(size: number, paginationToken?: string, filter?: string, filteredIds?: Array<number>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPage(size, paginationToken, filter, filteredIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerNotificationToken(body: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerNotificationToken(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnAvatar(editFile: EditFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnAvatar(editFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Passwords} passwords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnPassword(passwords: Passwords, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnPassword(passwords, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOwnUser(editUser: EditUser, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOwnUser(editUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOwnUser(options?: any): AxiosPromise<User> {
            return localVarFp.getOwnUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {string} [paginationToken] 
         * @param {string} [filter] 
         * @param {Array<number>} [filteredIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPage(size: number, paginationToken?: string, filter?: string, filteredIds?: Array<number>, options?: any): AxiosPromise<UserPage> {
            return localVarFp.getUserPage(size, paginationToken, filter, filteredIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerNotificationToken(body: string, options?: any): AxiosPromise<string> {
            return localVarFp.registerNotificationToken(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditFile} editFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnAvatar(editFile: EditFile, options?: any): AxiosPromise<any> {
            return localVarFp.updateOwnAvatar(editFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Passwords} passwords 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnPassword(passwords: Passwords, options?: any): AxiosPromise<User> {
            return localVarFp.updateOwnPassword(passwords, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditUser} editUser 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOwnUser(editUser: EditUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateOwnUser(editUser, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getOwnUser(options?: any) {
        return UserApiFp(this.configuration).getOwnUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} size 
     * @param {string} [paginationToken] 
     * @param {string} [filter] 
     * @param {Array<number>} [filteredIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserPage(size: number, paginationToken?: string, filter?: string, filteredIds?: Array<number>, options?: any) {
        return UserApiFp(this.configuration).getUserPage(size, paginationToken, filter, filteredIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerNotificationToken(body: string, options?: any) {
        return UserApiFp(this.configuration).registerNotificationToken(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditFile} editFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOwnAvatar(editFile: EditFile, options?: any) {
        return UserApiFp(this.configuration).updateOwnAvatar(editFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Passwords} passwords 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOwnPassword(passwords: Passwords, options?: any) {
        return UserApiFp(this.configuration).updateOwnPassword(passwords, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditUser} editUser 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateOwnUser(editUser: EditUser, options?: any) {
        return UserApiFp(this.configuration).updateOwnUser(editUser, options).then((request) => request(this.axios, this.basePath));
    }
}


