import React from "react";
import {TextField, TextFieldProps} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    noBorder: {
        border: "none",
    },
}));

const InputField = (props: TextFieldProps) => {
    const classes = useStyles();
    const patchedProps: TextFieldProps = {
        ...props,
        InputProps: {
            ...props.InputProps,
            classes: {
                ...props.InputProps?.classes,
                notchedOutline: classes.noBorder
            }
        },
        style: {
            backgroundColor: '#CCEAE7',
            borderRadius: '19px'
        }
    } as TextFieldProps;
    return <TextField {...patchedProps} />;
}

export default InputField;
