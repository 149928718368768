import {ChangeEventHandler, forwardRef, ForwardRefExoticComponent, RefAttributes} from "react";
import WithLabel from "./WithLabel";

interface Props {
    className?: string;
    label: string
    type: string;
    disabled?: boolean;
    value?: string | ReadonlyArray<string> | number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onEnter?: () => void;
    inputProps?: any
}

const Input: ForwardRefExoticComponent<Props & RefAttributes<HTMLInputElement>> = forwardRef<HTMLInputElement, Props>(
    (
        {
            className,
            type,
            label,
            disabled,
            value,
            onChange,
            onEnter,
            inputProps
        },
        ref
    ) => (
        <WithLabel label={label} className={className}>
            <input
                ref={ref}
                type={type}
                spellCheck={false}
                disabled={disabled}
                value={value}
                onChange={onChange}
                onKeyPress={!onEnter ? undefined : e => {
                    if (e.key === 'Enter') onEnter!();
                }}
                {...inputProps}
            />
        </WithLabel>
    )
);

export default Input;
