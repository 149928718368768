import {decode} from "jsonwebtoken";

class TokenService {
    getAccessToken(): string|null {
        return TokenService.getToken("access_token");
    }

    getRefreshToken(): string|null {
        return TokenService.getToken("refresh_token");
    }

    isAuthenticated(): boolean {
        return !!this.getRefreshToken();
    }

    isAdmin(): boolean {
        const token = localStorage.getItem("access_token");
        if (!token) return false;
        return TokenService.parseJwt(token).roles === "admin";
    }

    getUsername(): string {
        const token = localStorage.getItem("access_token");
        return !!token ? TokenService.parseJwt(token).name : "";
    }

    private static parseJwt(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    };

    private static getToken(name: string): string|null {
        const token = localStorage.getItem(name);
        return !!token && !TokenService.isExpired(token) ? token : null;
    }

    private static isExpired(token: string): boolean {
        try {
            const {exp} = decode(token) as {exp: number};
            if (exp < (new Date().getTime() + 1) / 1000) return true;
        } catch (err) {
            return true;
        }
        return false;
    }
}

const singleton = new TokenService();

export function useTokens() {
    return singleton;
}
