import React, {FC, useContext, useEffect, useState} from "react";
import styles from "./UserPage.module.css";
import Input from "../../input/Input";
import {ButtonBase, CircularProgress, TablePagination} from "@mui/material";
import UserLine from "./UserLine";
import {AddRounded} from "@mui/icons-material";
import UserDialogProvider, {UserDialogContext} from "./dialog/edit-user/UserDialogProvider";
import {User, UserPage} from "../../../../generated";
import ApiContext from "../../../contextes/api/ApiContext";
import {showErrorSnackbar} from "../../Snackbar";
import {AxiosError} from "axios";

interface State {
    filter: string;
    pages: UserPage[];
    isLoading: boolean;
    pageIndex: number;
    rowsPerPage: number;
}

interface FabProps {
    onChange: (user: User) => void;
}

const Fab: FC<FabProps> = ({onChange}) => {
    const dialog = useContext(UserDialogContext);
    function open() {
        dialog
            .open()
            .subscribe(u => onChange(u));
    }
    return <ButtonBase className={styles.fab} onClick={open}>
        <AddRounded/>
    </ButtonBase>;
}

const UserPageComponent: FC = () => {
    const api = useContext(ApiContext);
    const [{filter, pages, isLoading, pageIndex, rowsPerPage}, setState] = useState<State>({
        filter: "",
        isLoading: false,
        pages: [],
        pageIndex: 0,
        rowsPerPage: 10
    });
    const reload = () => {
        setState(ps => ({...ps, isLoading: true}));
        const paginationToken = pages.length > 0 && pages[pages.length - 1].nextPageToken;
        api.users
            .getUserPage(rowsPerPage, paginationToken ? paginationToken : undefined, filter)
            .then(({data}) => setState(ps => ({
                ...ps,
                isLoading: false,
                pages: [...ps.pages, data]
            })))
            .catch(async (e: AxiosError) => {
                await showErrorSnackbar(e, "Retry");
                setState(ps => ({...ps, isLoading: false}));
            });
    };
    useEffect(reload, [api, pageIndex, filter, rowsPerPage]);
    console.log(pages);
    return (
        <UserDialogProvider>
            <Input
                className={styles.filter}
                label="Filter"
                type="text"
                onChange={event => setState(ps => ({...ps, filter: event.target.value}))}
            />
            <div className={styles.lineContainer}>
                {
                    isLoading
                        ? (
                            <div className={styles.loadingContainer}>
                                <CircularProgress />
                            </div>
                        )
                        : (
                            pages.length > 0 && pages[pageIndex].items
                                .map(u => <UserLine
                                    key={u.id}
                                    value={u}
                                    onChange={u => setState(ps => {
                                        let pages = ps.pages;
                                        if (pages.length > 0) {
                                            pages[ps.pageIndex] = {
                                                ...pages[ps.pageIndex],
                                                items: pages[ps.pageIndex].items
                                                    .map(i => i.id === u.id ? u : i)
                                            };
                                        }
                                        return {...ps, pages: pages};
                                    })}/>
                                )
                        )
                }
            </div>
            <div className={styles.paginationContainer}>
                {
                    pages.length > 0 && <TablePagination
                        component="div"
                        count={pages[pages.length - 1].count}
                        page={pageIndex}
                        onPageChange={(_, page) => setState(ps => ({
                            ...ps,
                            pageIndex: page
                        }))}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 50]}
                        onRowsPerPageChange={e => setState(ps => ({
                            ...ps,
                            rowsPerPage: parseInt(e.target.value, 10),
                            pages: [],
                            pageIndex: 0
                        }))}
                    />
                }
            </div>
            <Fab onChange={reload} />
        </UserDialogProvider>
    );
};

export default UserPageComponent;
