import {FC, useContext} from "react";
import styles from "./UserPage.module.css";
import {IconButton} from "@mui/material";
import {CreateRounded} from "@mui/icons-material";
import {UserDialogContext} from "./dialog/edit-user/UserDialogProvider";
import {PasswordDialogContext} from "./dialog/change-password/PasswordDialogProvider";
import {User} from "../../../../generated";
import {filter} from "rxjs/operators";

interface Props {
    value: User;
    onChange: (user: User) => void;
}

const UserLine: FC<Props> = ({value, onChange}) => {
    const userDialog = useContext(UserDialogContext);
    const passwordDialog = useContext(PasswordDialogContext);
    function openDialog() {
        userDialog.open(value)
            .pipe(filter(v => !!v))
            .subscribe(u => onChange(u));
    }
    return <div className={styles.userLine}>
        <span className={styles.username}>{value.username}</span>
        <span className={styles.email}>{value.email}</span>
        <span className={styles.actions}>
            <IconButton onClick={() => passwordDialog.open(value.id)}>
                <span className={`material-icons-round material-icons ${styles.color}`}>password</span>
            </IconButton>
            <IconButton onClick={openDialog}>
                <CreateRounded className={styles.color}/>
            </IconButton>
        </span>
    </div>;
};

export default UserLine;
