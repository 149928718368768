import React, {ChangeEvent, FC, useContext, useRef, useState} from "react";
import Input from "../../input/Input";
import styles from "./LoginForm.module.css";
import ProgressIndicator from "../../progress-indicator/ProgressIndicator";
import ApiContext from "../../../contextes/api/ApiContext";
import {showErrorSnackbar, showSnackbar} from "../../Snackbar";
import {ButtonBase} from "@mui/material";
import {AxiosError} from "axios";

interface State {
    isLoading: boolean;
    username: string;
    password: string;
}

const LoginForm: FC = () => {
    const [{isLoading, username, password}, setState] = useState<State>({
        isLoading: false,
        username: "",
        password: ""
    });
    const api = useContext(ApiContext);

    function onUsernameChanged(event: ChangeEvent<HTMLInputElement>) {
        setState(ps => ({...ps, username: event.target.value}));
    }

    function onPasswordChanged(event: ChangeEvent<HTMLInputElement>) {
        setState(ps => ({...ps, password: event.target.value}));
    }

    function submit() {
        if (username.length === 0 || password.length === 0) {
            showSnackbar("Fill in your credentials", "Dismiss");
            return;
        }
        setState(ps => ({...ps, isLoading: true}));
        api.authentication
            .login({username: username, password: password})
            .then(({data: {tokens: {token, refreshToken}}}) => {
                setState(ps => ({...ps, isLoading: false}));
                localStorage.setItem("access_token", token);
                localStorage.setItem("refresh_token", refreshToken);
                window.location.reload();
            })
            .catch(async (e: AxiosError) => {
                setState(ps => ({...ps, isLoading: false}));
                await showErrorSnackbar(e, "Dismiss");
            });
    }
    const passwordInputRef = useRef<HTMLInputElement|null>(null);
    return <div className={styles.container}>
        <Input
            label="Username or email"
            type="text"
            disabled={isLoading}
            value={username}
            onChange={onUsernameChanged}
            onEnter={() => passwordInputRef.current && passwordInputRef.current!.focus()}
        />
        <div className={styles.space}/>
        <Input
            ref={passwordInputRef}
            label="Password"
            type="password"
            disabled={isLoading}
            value={password}
            onChange={onPasswordChanged}
            onEnter={submit}
        />
        <div className={styles.loginButtonContainer}>
            <ButtonBase
                className={`${styles.loginButton} ${isLoading ? styles.loading : ""}`}
                onClick={submit}
            >
                {isLoading ? <ProgressIndicator/> : "login"}
            </ButtonBase>
        </div>
    </div>;
};

export default LoginForm;
