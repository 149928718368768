import React, {FC, useCallback, useContext, useRef, useState} from "react";
import {CircularProgress, InputAdornment} from "@mui/material";
import AuthorizedAvatar from "../authorized-avatar/AuthorizedAvatar";
import {UploadFile} from "@mui/icons-material";
import InputField from "../input/InputField";
import ApiContext from "../../contextes/api/ApiContext";
import {showErrorSnackbar, showSnackbar} from "../Snackbar";
import {AxiosError} from "axios";
import {Identifiable} from "../../../generated";

interface Props {
    file?: Identifiable | undefined;
    onChange: (value: Identifiable) => void;
}

interface State {
    isLoading: boolean;
}

const UploadInputField: FC<Props> = ({file, onChange}) => {
    const ref = useRef<HTMLInputElement|null>(null);
    const api = useContext(ApiContext);
    const [{isLoading}, setState] = useState<State>({
        isLoading: false
    });
    const handleChange = useCallback(async (event) => {
        const files = [...event.target.files] as File[];
        if (files.length <= 0) return;
        const [file] = files;
        try {
            setState(ps => ({...ps, isLoading: true}));
            const {data} = await api.file
                .createFile({name: file.name, contentType: file.type, size: file.size});
            const response = await api.file
                .uploadFileContent(data.id, await file.arrayBuffer());
            if (response.status === 201) {
                onChange({
                    id: data.id,
                    name: data.name
                });
            } else {
                showSnackbar("The image was not created correctly", "Dismiss");
            }
            setState(ps => ({...ps, isLoading: false}));
        } catch (e: any) {
            if (e.isAxiosError) {
                await showErrorSnackbar(e as AxiosError, "Dismiss");
            } else {
                showSnackbar(`An unexpected error occurred: ${e.toString()}`, "Dismiss");
            }
        }
    }, [api.file, onChange]);
    console.log(file);
    return (
        <>
            <InputField
                fullWidth
                value={file?.name}
                inputProps={{
                    style: {
                        marginLeft: 5,
                        marginRight: 5
                    }
                }}
                InputProps={{
                    readOnly: true,
                    startAdornment: (
                        <InputAdornment position="start">
                            <AuthorizedAvatar
                                imageId={file?.id ?? -1}
                                sx={{ width: 36, height: 36 }}
                                style={{
                                    borderRadius: 11
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {
                                isLoading
                                    ? <CircularProgress size={30} thickness={5} />
                                    : <UploadFile />
                            }
                        </InputAdornment>
                    )
                }}
                onClick={() => ref.current && ref.current!.click()}
            />
            <input
                ref={ref}
                type="file"
                accept="image/*"
                hidden
                onChange={handleChange}
            />
        </>
    );
};

export default UploadInputField;
