import React, {FC, PropsWithChildren} from "react";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import styles from "./EditDialog.module.css";

interface Props {
    title: string;
    dialogProps: DialogProps;
    actions: JSX.Element
}

const EditDialog: FC<PropsWithChildren<Props>> = ({
    children,
    title,
    dialogProps,
    actions
}) => (
    <Dialog {...dialogProps}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={styles.content}>
            {children}
        </DialogContent>
        <DialogActions className={styles.actions}>
            {actions}
        </DialogActions>
    </Dialog>
);

export default EditDialog;
