import {createContext} from "react";
import {
    AdminUserApi,
    AuthenticationApi,
    Configuration, FileApi, SpeciesApi,
    UserApi
} from "../../../generated";
import axios, {AxiosError} from "axios";

interface Apis {
    authentication: AuthenticationApi;
    users: UserApi;
    adminUsers: AdminUserApi;
    species: SpeciesApi;
    file: FileApi;
}

export function apiBuilder(): Apis {
    const instance = axios.create();
    instance.interceptors.response.use(undefined, async (error: AxiosError) => {
        if (error.response?.status !== 401) return Promise.reject(error);
        const {data: {token, refreshToken}} = await new AuthenticationApi(
            undefined,
            "/api",
            axios.create({
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("refresh_token")}`
                }
            })
        ).refreshToken();
        localStorage.setItem("access_token", token);
        localStorage.setItem("refresh_token", refreshToken);
        const config = {...error.config};
        config.headers["Authorization"] = `Bearer ${token}`;
        return axios
            .create()
            .request(config);
    });
    const config = new Configuration({accessToken: localStorage.getItem("access_token") ?? undefined});
    const basePath = "/api";

    return {
        authentication: new AuthenticationApi(config, basePath, instance),
        users: new UserApi(config, basePath, instance),
        adminUsers: new AdminUserApi(config, basePath, instance),
        species: new SpeciesApi(config, basePath, instance),
        file: new FileApi(config, basePath, instance)
    };
}

const apiContext = createContext<Apis>(apiBuilder());
export default apiContext;
