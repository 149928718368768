import React, {FC, useEffect} from 'react';
import {toast, useToasterStore} from "react-hot-toast";
import {Switch, Route, Redirect} from "react-router";
import LoginPage from "./components/login/LoginPage";
import {useTokens} from "./services/TokenService";
import Navbar from "./components/navbar/Navbar";
import DownloadPage from "./components/download/DownloadPage";
import PasswordDialogProvider from "./components/management/user/dialog/change-password/PasswordDialogProvider";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import ApiDocPage from "./components/api-doc/ApiDocPage";
import ManagementPage from "./components/management/ManagementPage";

const theme = createTheme({
    palette: {
        primary: {
            main: "#54B8AE",
        },
    },
    shape: {
        borderRadius: "19px",
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    color: 'white',
                },
            },
        },
    },
});

const App: FC = () => {
    const { toasts } = useToasterStore();
    useEffect(() => {
        toasts
            .filter((t) => t.visible) // Only consider visible toasts
            .filter((_, i) => i >= 1) // Is toast index over limit?
            .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
    }, [toasts]);
    const tokens = useTokens();
    const isAdmin = tokens.isAdmin();
    return !tokens.isAuthenticated()
        ? <Switch>
            <Route path="/login">
                <LoginPage />
            </Route>
            <Route>
                <Redirect to="/login" />
            </Route>
        </Switch>
        : <PasswordDialogProvider>
            <ThemeProvider theme={theme}>
                <Navbar isAdmin={isAdmin} />
                <Switch>
                    <Route path="/download">
                        <DownloadPage />
                    </Route>
                    {
                        isAdmin
                        && <>
                            <Route path="/management">
                                <ManagementPage />
                            </Route>
                            <Route path="/api-doc">
                                <ApiDocPage />
                            </Route>
                        </>
                    }
                    <Route render={() => <Redirect to="/download" />} />
                </Switch>
            </ThemeProvider>
        </PasswordDialogProvider>
};

export default App;
