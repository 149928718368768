import {FC, useContext, useState} from "react";
import styles from "./Navbar.module.css";
import {Link} from "react-router-dom";
import {
    ButtonBase,
    Collapse, IconButton,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem
} from "@mui/material";
import {AccountCircleRounded, MenuRounded} from "@mui/icons-material";
import ApiContext from "../../contextes/api/ApiContext";
import {PasswordDialogContext} from "../management/user/dialog/change-password/PasswordDialogProvider";
import {useTokens} from "../../services/TokenService";
import {AxiosError} from "axios";

interface Props {
    isAdmin: boolean;
}

interface State {
    anchorEl: null | HTMLElement;
    mobileMenuOpen: boolean;
}

const Navbar: FC<Props> = ({isAdmin}) => {
    const api = useContext(ApiContext);
    const dialog = useContext(PasswordDialogContext);
    const [{anchorEl, mobileMenuOpen}, setState] = useState<State>({
        anchorEl: null,
        mobileMenuOpen: false
    });
    const closeMobileMenu = () => setState(ps => ({...ps, mobileMenuOpen: false}));
    function logout() {
        api.authentication.logout({
            token: localStorage.getItem("access_token") ?? "",
            refreshToken: localStorage.getItem("refresh_token") ?? ""
        })
            .then(() => {
                setState(ps => ({...ps, anchorEl: null, mobileMenuOpen: false}));
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                window.location.reload();
            })
            .catch((e: AxiosError) => {
                console.log(e);
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                window.location.reload();
            });
    }
    const username = useTokens().getUsername();
    return <div className={styles.shadow}>
        <div className={styles.container}>
            <div className={styles.logo}>
                <span className={styles.title}>Thirsty Plants</span>
                <span className={`${styles.subtitle} primary-color`}>or rather not anymore !</span>
            </div>
            <div className={`${styles.navbarEnd} ${styles.desktopOnly}`}>
                <Link to="/download" className={!isAdmin ? styles.marginRight : undefined}>
                    <ButtonBase className={styles.button}>Download</ButtonBase>
                </Link>
                {
                    isAdmin
                    && <>
                        <Link to="/management">
                            <ButtonBase className={styles.button}>Management</ButtonBase>
                        </Link>
                        <Link to="/api-doc" className={styles.marginRight}>
                            <ButtonBase className={styles.button}>API</ButtonBase>
                        </Link>
                    </>
                }
                <IconButton
                    className={styles.icon}
                    onClick={e => setState(ps => ({...ps, anchorEl: e.currentTarget}))}
                >
                    <AccountCircleRounded/>
                </IconButton>
                <Menu
                    PaperProps={{
                        className: `${styles.desktopOnly} ${styles.shadow}`
                    }}
                    keepMounted
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={!!anchorEl}
                    onClose={() => setState(ps => ({...ps, anchorEl: null}))}
                >
                    <MenuItem disabled>Hi {username}</MenuItem>
                    <MenuItem onClick={() => {
                        setState(ps => ({...ps, anchorEl: null}));
                        dialog.open();
                    }}>
                        Change password
                    </MenuItem>
                    <MenuItem onClick={logout}>Logout</MenuItem>
                </Menu>
            </div>
            <div className={styles.mobileOnly}>
                <IconButton
                    className={styles.icon}
                    onClick={() => setState(ps => ({...ps, mobileMenuOpen: !ps.mobileMenuOpen}))}
                >
                    <MenuRounded />
                </IconButton>
            </div>
        </div>
        <Collapse in={mobileMenuOpen} className={styles.mobileOnly}>
            <List className={styles.mobileMenu}>
                <ListItem>
                    <ListItemText className={styles.menuText} primary={`Hi ${username}`} />
                </ListItem>
                <Link to="/download" className={styles.link}>
                    <ListItem button onClick={closeMobileMenu}>
                        <ListItemText primary="Download" />
                    </ListItem>
                </Link>
                {
                    isAdmin
                    && <Link to="/user" className={styles.link}>
                        <ListItem button onClick={closeMobileMenu}>
                            <ListItemText  primary="Users" />
                        </ListItem>
                    </Link>
                }
                <ListItem button onClick={() => {
                    closeMobileMenu();
                    dialog.open();
                }}>
                    <ListItemText primary="Change password" />
                </ListItem>
                <ListItem button onClick={logout}>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Collapse>
    </div>;
};

export default Navbar;
