import {FC, PropsWithChildren} from "react";
import styles from "./Input.module.css";

interface Props {
    className?: string | undefined;
    label: string;
    dynamicHeight?: boolean;
    withoutBackground?: boolean;
    fullWidth?: boolean;
}

const WithLabel: FC<PropsWithChildren<Props>> = ({
    className,
    label,
    dynamicHeight,
    withoutBackground,
    fullWidth,
    children
}) => {
    return (
        <div
            className={`${styles.container} ${className ? className : ""}`}
            style={{width: fullWidth ? '100%' : undefined}}
        >
            <label className={styles.inputLabel}>{label}</label>
            <div className={styles.inputContainer} style={{
                backgroundColor: withoutBackground ? undefined : '#CCEAE7',
                height: !dynamicHeight ? 56 : undefined,
                width: fullWidth ? '100%' : undefined
            }}>
                {children}
            </div>
        </div>
    );
}

export default WithLabel;
