import styles from "./LoginPage.module.css";
import React, {FC} from "react";
import Title from "../title/Title";
import LoginForm from "./form/LoginForm";
import {Container} from "@mui/material";

const LoginPage: FC = () => {
    return <Container className={styles.container}>
        <div className={styles.title}>
            <Title />
        </div>
        <LoginForm/>
    </Container>
}

export default LoginPage;
