import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import {Toaster} from 'react-hot-toast';
import {BrowserRouter} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
        <Toaster position="bottom-center" toastOptions={{className: "snackbar"}}/>
    </React.StrictMode>,
    document.getElementById('root')
);
