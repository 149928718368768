import styles from "../../user/dialog/edit-user/UserDialogProvider.module.css";
import {Autocomplete, ButtonBase, CircularProgress} from "@mui/material";
import Input from "../../../input/Input";
import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import {EditSpecies, LightSpecies, Species} from "../../../../../generated";
import EditDialog from "../../../dialog/EditDialog";
import ApiContext from "../../../../contextes/api/ApiContext";
import {AxiosError} from "axios";
import {showErrorSnackbar} from "../../../Snackbar";
import InputField from "../../../input/InputField";
import WithLabel from "../../../input/WithLabel";
import UploadInputField from "../../../upload-input-field/UploadInputField";
import {WithImage} from "../../../../models/WithImage";
import FloatRangeInput from "./FloatRangeInput";

interface Props {
    species: LightSpecies | undefined;
    open: boolean;
    onClose: (value?: Species | undefined) => void;
}

interface State {
    isLoading: boolean;
    isSaving: boolean;
    edit: WithImage<EditSpecies>;
}

const SpeciesDialog: FC<Props> = ({open, onClose, species}) => {
    const api = useContext(ApiContext).species;
    const [{isLoading, isSaving, edit}, setState] = useState<State>({
        isLoading: false,
        isSaving: false,
        edit: {
            name: "",
            commonName: "",
            tags: [],
            imageId: -1,
            growth: {
                soilMoisture: {
                    minimum: 0,
                    perfect: 0,
                    maximum: 0
                },
                lightExposure: {
                    minimum: 0,
                    perfect: 0,
                    maximum: 0
                },
                temperature: {
                    minimum: 0,
                    perfect: 0,
                    maximum: 0
                },
                humidity: {
                    minimum: 0,
                    perfect: 0,
                    maximum: 0
                }
            }
        }
    });
    const onSave = useCallback(() => {
        setState(ps => ({...ps, isSaving: true}));
        const request = !!species
            ? api.updateSpecies(species.id, edit)
            : api.createSpecies(edit);
        request
            .then(({data}) => onClose(data))
            .catch(async (e: AxiosError) => {
                await showErrorSnackbar(e, "Retry");
                setState(ps => ({...ps, isSaving: false}));
            });
    }, [api, species]);
    useEffect(() => {
        if (species) {
            setState(ps => ({...ps, isLoading: true}));
            api.getSpecies(species.id)
                .then(({data}) => setState({
                    isLoading: false,
                    isSaving: false,
                    edit: {...data, imageId: data.image.id, }
                }))
                .catch(async (e: AxiosError) => {
                    await showErrorSnackbar(e, "Retry");
                    setState(ps => ({...ps, isLoading: false}));
                });
        }
    });
    return (
        <EditDialog
            title={!!species ? 'Edit species' : 'New species'}
            dialogProps={{
                onClose: () => onClose(),
                open: open,
                PaperProps: {className: styles.container},
                fullWidth: true,
                maxWidth: "sm"
            }}
            actions={<>
                <ButtonBase className={styles.button} onClick={() => onClose()}>
                    Cancel
                </ButtonBase>
                <ButtonBase className={`${styles.button} ${styles.primaryButton}`} onClick={onSave}>
                    Save
                </ButtonBase>
            </>}
        >
            {
                isLoading
                    ? (
                        <CircularProgress/>
                    )
                    : (
                        <>
                            <WithLabel label="Image" className={styles.input}>
                                <UploadInputField
                                    file={edit.image}
                                    onChange={i => setState(ps => ({
                                        ...ps,
                                        edit: {
                                            ...ps.edit,
                                            imageId: i.id,
                                            image: i
                                        }
                                    }))}
                                />
                            </WithLabel>
                            <Input
                                className={styles.input}
                                label="Name"
                                type="text"
                                value={edit.name ?? ""}
                                onChange={e => setState(ps => ({...ps, edit: {...ps.edit, name: e.target.value}}))}
                            />
                            <Input
                                className={styles.input}
                                label="Common name"
                                type="text"
                                value={edit.commonName ?? ""}
                                onChange={e => setState(ps => ({...ps, edit: {...ps.edit, commonName: e.target.value}}))}
                            />

                            <WithLabel label="Tags" className={styles.input} dynamicHeight>
                                <Autocomplete
                                    className={styles.input}
                                    fullWidth
                                    multiple
                                    filterSelectedOptions
                                    freeSolo
                                    options={[]}
                                    value={edit.tags ?? []}
                                    onChange={(e, v) => {
                                        setState(ps => ({
                                            ...ps,
                                            edit: {...ps.edit, tags: v as string[]}
                                        }))
                                    }}
                                    renderInput={params => (
                                        <InputField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                style: {
                                                    marginLeft: 5,
                                                    marginRight: 5
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </WithLabel>
                            <WithLabel label="Moisture" withoutBackground fullWidth className={styles.input}>
                                <FloatRangeInput
                                    value={edit.growth.soilMoisture}
                                    onChange={v => setState(ps => ({
                                        ...ps,
                                        edit: {...ps.edit, growth: {...ps.edit.growth, soilMoisture: v}}
                                    }))}
                                />
                            </WithLabel>
                            <WithLabel label="Light exposure" withoutBackground fullWidth className={styles.input}>
                                <FloatRangeInput
                                    value={edit.growth.lightExposure}
                                    onChange={v => setState(ps => ({
                                        ...ps,
                                        edit: {...ps.edit, growth: {...ps.edit.growth, lightExposure: v}}
                                    }))}
                                />
                            </WithLabel>
                            <WithLabel label="Temperature" withoutBackground fullWidth className={styles.input}>
                                <FloatRangeInput
                                    value={edit.growth.temperature}
                                    onChange={v => setState(ps => ({
                                        ...ps,
                                        edit: {...ps.edit, growth: {...ps.edit.growth, temperature: v}}
                                    }))}
                                />
                            </WithLabel>
                            <WithLabel label="Humidity" withoutBackground fullWidth className={styles.input}>
                                <FloatRangeInput
                                    value={edit.growth.humidity}
                                    onChange={v => setState(ps => ({
                                        ...ps,
                                        edit: {...ps.edit, growth: {...ps.edit.growth, humidity: v}}
                                    }))}
                                />
                            </WithLabel>
                        </>
                    )
            }
        </EditDialog>
    )
};
export default SpeciesDialog;
