import React, {createContext, FC, PropsWithChildren, useCallback, useState} from "react";
import {Observable, of, Subject} from "rxjs";
import {LightSpecies, Species} from "../../../../../generated";
import SpeciesDialog from "./SpeciesDialog";

interface State {
    species?: LightSpecies;
    open: boolean;
    subject?: Subject<Species>;
}

interface Context {
    open: (species?: LightSpecies) => Observable<LightSpecies>;
}

export const SpeciesDialogContext = createContext<Context>({
    open: (_) => of()
});

const SpeciesDialogProvider: FC<PropsWithChildren<{}>> = ({children}) => {
    const [{species, open}, setState] = useState<State>({
        open: false
    });
    const onClose = useCallback((value?: Species) => setState(ps => {
        if (value) ps.subject?.next(value);
        ps.subject?.complete();
        return {
            open: false,
            species: undefined,
            subject: undefined
        };
    }), []);
    return (
        <SpeciesDialogContext.Provider value={{
            open: s => {
                const subject = new Subject<Species>();
                setState({
                    open: true,
                    species: s,
                    subject: subject
                });
                console.log('open species dialog');
                return subject.asObservable();
            }
        }}>
            {children}
            <SpeciesDialog
                open={open}
                species={species}
                onClose={onClose}
            />
        </SpeciesDialogContext.Provider>
    );
}

export default SpeciesDialogProvider;
