import {createContext, FC, PropsWithChildren, useContext, useState} from "react";
import {ButtonBase} from "@mui/material";
import Input from "../../../../input/Input";
import styles from "./UserDialogProvider.module.css";
import {User} from "../../../../../../generated";
import ApiContext from "../../../../../contextes/api/ApiContext";
import {showErrorSnackbar, showSnackbar} from "../../../../Snackbar";
import {AxiosError} from "axios";
import {Observable, of, Subject} from "rxjs";
import EditDialog from "../../../../dialog/EditDialog";

interface State {
    id?: number;
    username: string;
    email: string;
    password: string;
    secondPassword: string;
    open: boolean;
    subject?: Subject<User>;
}

interface Context {
    open: (user?: User) => Observable<User>;
}

export const UserDialogContext = createContext<Context>({
    open: (_) => of()
});

const UserDialogProvider: FC<PropsWithChildren<{}>> = ({children}) => {
    const api = useContext(ApiContext);
    const [{id, username, email, open, password, secondPassword, subject}, setState] = useState<State>({
        username: "",
        email: "",
        password: "",
        secondPassword: "",
        open: false
    });
    const close = () => {
        subject?.complete();
        setState(ps => ({
            ...ps,
            open: false,
            username: "",
            email: "",
            password: "",
            secondPassword: "",
            subject: undefined
        }));
    }
    const save = () => {
        if (username.length === 0 || email.length === 0) {
            showSnackbar("All the fields are required", "Dismiss");
            return;
        }
        if (password !== secondPassword) {
            showSnackbar("The 2 passwords are not equals", "Dismiss");
            return;
        }
        const promise = !!id
            ? api.adminUsers.updateUser(id, {username: username, email: email})
            : api.adminUsers.createUser({username: username, email: email, password: password});
        promise
            .then(({data}) => {
                subject?.next(data);
                close();
            })
            .catch(async (e: AxiosError) => await showErrorSnackbar(e, "Dismiss"));
    };
    return <UserDialogContext.Provider value={{
        open: (user) => {
            const subject = new Subject<User>();
            setState({
                open: true,
                id: user?.id,
                username: user?.username ?? "",
                email: user?.email ?? "",
                password: "",
                secondPassword: "",
                subject: subject
            })
            return subject.asObservable();
        }
    }}>
        {children}
        <EditDialog
            title={!!id ? "Edit user" : "New user"}
            dialogProps={{
                onClose: close,
                open: open,
                PaperProps: {className: styles.container},
                fullWidth: true,
                maxWidth: "xs"
            }}
            actions={<>
                <ButtonBase className={styles.button} onClick={close}>
                    Cancel
                </ButtonBase>
                <ButtonBase className={`${styles.button} ${styles.primaryButton}`} onClick={save}>
                    Save
                </ButtonBase>
            </>}
        >
            <Input
                className={styles.input}
                label="Username"
                type="text"
                value={username}
                onChange={e => setState(ps => ({...ps, username: e.target.value}))}
            />
            <Input
                className={styles.input}
                label="Email"
                type="text"
                value={email}
                onChange={e => setState(ps => ({...ps, email: e.target.value}))}
            />
            {
                !id && <>
                    <Input
                        className={styles.input}
                        label="Password"
                        type="password"
                        value={password}
                        onChange={e => setState(ps => ({...ps, password: e.target.value}))}
                    />
                    <Input
                        className={styles.input}
                        label="Confirm password"
                        type="password"
                        value={secondPassword}
                        onChange={e => setState(ps => ({...ps, secondPassword: e.target.value}))}
                    />
                </>
            }
        </EditDialog>
    </UserDialogContext.Provider>;
};

export default UserDialogProvider;
