import {Alert, ButtonBase, TablePagination} from "@mui/material";
import React, {FC, useContext, useEffect, useState} from "react";
import {LightSpecies, LightSpeciesPage} from "../../../../generated";
import styles from "./SpeciesPage.module.css";
import Input from "../../input/Input";
import SpeciesLine from "./SpeciesLine";
import ApiContext from "../../../contextes/api/ApiContext";
import {AxiosError} from "axios";
import {showErrorSnackbar} from "../../Snackbar";
import {AddRounded} from "@mui/icons-material";
import SpeciesDialogProvider, {SpeciesDialogContext} from "./dialog/SpeciesDialogProvider";

interface State {
    filter: string;
    pageIndex: number;
    pages: LightSpeciesPage[];
    isLoading: boolean;
    rowsPerPage: number;
}

interface FabProps {
    onChange: (species: LightSpecies) => void;
}

const Fab: FC<FabProps> = ({onChange}) => {
    const dialog = useContext(SpeciesDialogContext);
    return (
        <ButtonBase className={styles.fab} onClick={() => dialog.open().subscribe(s => onChange(s))}>
            <AddRounded/>
        </ButtonBase>
    );
}

const pageBuilder = (page: LightSpeciesPage) => {
    return page.items.length === 0
        ? (
            <Alert severity="info">
                No species found
            </Alert>
        )
        : page.items.map(s => <SpeciesLine value={s} />)
}

const SpeciesPage: FC = () => {
    const api = useContext(ApiContext);
    const [{filter, pages, isLoading, pageIndex, rowsPerPage}, setState] = useState<State>({
        filter: '',
        pageIndex: 0,
        pages: [],
        isLoading: false,
        rowsPerPage: 10
    });
    const reload = () => {
        setState(ps => ({...ps, isLoading: true}));
        const paginationToken = pages.length > 0 && pages[pages.length - 1].nextPageToken;
        api.species
            .getSpeciesPage(
                rowsPerPage,
                filter,
                paginationToken ? paginationToken : undefined
            )
            .then(({data}) => setState(ps => ({
                ...ps,
                isLoading: false,
                pages: [...ps.pages, data]
            })))
            .catch(async (e: AxiosError) => {
                await showErrorSnackbar(e, "Retry");
                setState(ps => ({...ps, isLoading: false}));
            });
    }
    useEffect(reload, [api, pageIndex, filter, rowsPerPage]);
    return (
        <SpeciesDialogProvider>
            <Input
                className={styles.filter}
                label="Filter"
                type="text"
                onChange={event => setState(ps => ({...ps, filter: event.target.value}))}
            />
            <div className={styles.lineContainer}>
                {pages.length > 0 && pageBuilder(pages[pageIndex])}
            </div>
            <div className={styles.paginationContainer}>
                {
                    pages.length > 0 && <TablePagination
                        component="div"
                        count={pages[pages.length - 1].count}
                        page={pageIndex}
                        onPageChange={(_, page) => setState(ps => ({
                            ...ps,
                            pageIndex: page
                        }))}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[10, 20, 50]}
                        onRowsPerPageChange={e => setState(ps => ({
                            ...ps,
                            rowsPerPage: parseInt(e.target.value, 10),
                            pages: [],
                            pageIndex: 0
                        }))}
                    />
                }
            </div>
            <Fab onChange={reload} />
        </SpeciesDialogProvider>
    );
};

export default SpeciesPage;
