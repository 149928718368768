import {FC} from "react";
import styles from "../user/UserPage.module.css";
import {LightSpecies} from "../../../../generated";

interface Props {
    value: LightSpecies;
}

const SpeciesLine: FC<Props> = ({value}) => {
    return <div className={styles.userLine}>
        <span className={styles.username}>{value.name}</span>
        <span className={styles.email}>{value.commonName}</span>
        <span className={styles.actions}>
        </span>
    </div>;
}

export default SpeciesLine;
