import React, {FC} from "react";
import {Container, Tab, Tabs} from "@mui/material";
import {Link, useHistory, useLocation} from "react-router-dom";

import styles from "./ManagementPage.module.css";
import UserPage from "./user/UserPage";
import SpeciesPage from "./species/SpeciesPage";

interface PathInfo {
    identifier: string;
    basePath: string;
}

function useLastRoutePath(): PathInfo {
    const { pathname } = useLocation();
    const pathElements = pathname.split('/');
    return {
        identifier: pathElements[pathElements.length - 1],
        basePath: pathElements.slice(0, -1).join('/')
    }
}

const tabs = [
    { label: 'Users', identifier: 'user', component: UserPage },
    { label: 'Species', identifier: 'species', component: SpeciesPage }
]

const ManagementPage: FC = () => {
    const history = useHistory();
    const path = useLastRoutePath();
    const tab = tabs.find(t => t.identifier === path.identifier);
    if (!tab) {
        history.push('/management/user');
        return null;
    }
    return (
        <Container maxWidth="md">
            <Tabs value={path.identifier} className={styles.tabContainer} TabIndicatorProps={{
                className: `${styles.tabIndicator}`,
                children: <span className={styles.innerTabIndicator} />,
            }}>
                {
                    tabs.map(({label, identifier}) => (
                        <Tab
                            key={identifier}
                            className={styles.tabItem}
                            label={label}
                            value={identifier}
                            to={`${path.basePath}/${identifier}`}
                            component={Link}
                        />
                    ))
                }
            </Tabs>
            <div className={styles.contentContainer}>
                <tab.component />
            </div>
        </Container>
    );
};

export default ManagementPage;
