import {Container, Tab, Tabs} from "@mui/material";
import {FC, useState} from "react";
import styles from "./ApiDocPage.module.css";
import SwipeableViews from 'react-swipeable-views';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css"

interface TabPanelProps {
    index: number;
    value: number;
    url: string;
}

function TabPanel(props: TabPanelProps) {
    const {value, index, url} = props;
    return <div hidden={value !== index}>
        {
            value === index
            && <SwaggerUI url={url} />
        }
    </div>;
}

const ApiDocPage: FC = () => {
    const [state, setState] = useState<number>(0);
    return <Container maxWidth="xl" className={styles.container}>
        <Tabs centered value={state} onChange={(_, v) => setState(v)} className={styles.tabs}>
            <Tab label="Application Api" value={0} />
            <Tab label="Sensor Api" value={1} />
        </Tabs>
        <SwipeableViews
            index={state}
            onChangeIndex={v => setState(v)}
        >
            <TabPanel value={state} index={0} url="/api/resources/openapi.yml" />
            <TabPanel value={state} index={1} url="https://sensor.thirsty-plants.be/api/resources/openapi.yml" />
        </SwipeableViews>
    </Container>
};

export default ApiDocPage;
